
.activeClass {
    background: #4d5aff;
    color: #ffffff;
}

.company-contain {
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 100px);

    .head-box {
        display: flex;
        justify-content: space-between;
        padding: 10px 30px 0 20px;

        .left-box {
            display: flex;

            .title {
                margin-top: 10px;
            }
        }

        .addbutton {
            display: flex;
            align-items: center;
            border: 1px solid #2338E6;
            cursor: pointer;
            width: 120px;
            height: 40px;

            span {
                margin-left: 10px;
                color: #2338E6
            }
        }
    }

    .divide {
        padding: 0 20px;
    }

    .filter-box {
        .box1 {
            padding: 0 20px 10px 20px;
            height: 40px;
            display: flex;

            span {
                margin-top: 8px;
                line-height: 32px;
                display: inline-block;
                width: 80px;
                height: 32px;
                text-align: center;
                cursor: pointer;
            }
        }
    }

    .table-box {
        display: flex;
        flex-direction: column;
        height: 1%;
        flex: 1;

        .showing {
            display: flex;
            align-items: center;
            /*width: 30px;*/
            height: 40px;
            margin-left: 10px;

            img {
                display: inline-block;
                width: 40px;
                height: 40px;
                margin-right: 10px;
            }

            .showing-name {
                max-width: 260px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }
    }
}
